import { useMemo } from "react";
import { useRecoilState } from "recoil";
import { ContentBox } from "src/components";
import { recoilStates } from "src/recoil/recoilStates";
import { addDays, dateFormat } from "src/utils";
import styled from "styled-components";

function Deposit() {
  const [bookingApplication, setBookingApplication] = useRecoilState(
    recoilStates.bookingApplication
  );

  const clubRelData = useMemo(() => {
    return bookingApplication?.clubRels.find((cr) => cr.year_booking_status_id === 4);
  }, [bookingApplication]);

  const day = addDays(new Date(clubRelData?.updated_at || Date.now()), 7);
  const clubD = clubRelData?.club?.[0];
  return (
    <Container className="flex-1">
      <p className="pay-comment">
        {dateFormat(day)} 까지 아래와 같이 보증금을 결제해 주시기 바랍니다.
      </p>
      <ContentBox
        colorTheme="gray"
        content={
          <div className="flex-1 d-flex jc-sa fd-c">
            <div>
              보증금 합계:{" "}
              {(
                (clubRelData?.meal_deposit ?? 0) + (clubRelData?.round_deposit ?? 0)
              ).toLocaleString()}{" "}
              원
            </div>
            <div>
              <div>예금주: {clubD?.account_depositor_name}</div>
              <div>은행명: {clubD?.account_bank_name}</div>
              <div>계좌번호: {clubD?.account_number}</div>
            </div>
          </div>
        }
      />
      <p className="pay-comment">
        ※ 보증금이 없거나 0원인 경우 별도 입금하실 필요가 없습니다.
      </p>
    </Container>
  );
}

export default Deposit;

const Container = styled.div`
  .pay-comment {
    align-self: center;
    padding: 15px 0px;
    font-size: 14px;
  }
`;
