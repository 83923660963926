import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Divider } from "src/components";
import { useAlert } from "src/hooks";
import styled from "styled-components";
import rehypeRaw from "rehype-raw";

function loadScript() {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = "https://t1.kakaocdn.net/kakao_js_sdk/2.7.4/kakao.min.js";
    script.crossOrigin = "anonymous";
    script.integrity = "sha384-DKYJZ8NLiK8MN4/C5P2dtSmLQ4KwPaoqAfyA/DfmEc1VDxu4yyC7wy6K1Hs90nka";
    script.onload = () => resolve(1);
    script.onerror = (err) => reject(err);
    document.head.appendChild(script);
  });
}

function loadBandScript() {
  return new Promise((resolve, reject) => {
    const tarElem: any = document.querySelector(".tar1");
    const script = document.createElement("script");
    const div = document.createElement("div");
    console.log("tarElem :", tarElem);
    tarElem.appendChild(script);
    tarElem.appendChild(div);

    script.src = "//developers.band.us/js/share/band-button.js?v=04122024";
    script.type = "text/javascript";
    script.id = "asdf";
    // script.crossOrigin = "anonymous";
    // script.integrity = "sha384-DKYJZ8NLiK8MN4/C5P2dtSmLQ4KwPaoqAfyA/DfmEc1VDxu4yyC7wy6K1Hs90nka";
    script.onload = () => resolve(1);
    script.onerror = (err) => reject(err);
  });
}
declare global {
  interface Window {
    Kakao: any;
    ShareBand: any;
  }
}
const arr = new Array(30).fill(1);
export default function My() {
  const [count, setCount] = useState(1);
  const fn1 = () => {
    return 33;
  };

  // const [isLoaded, setIsLoaded] = useState(false);

  const [kakao, setKakao] = useState<any>(null);
  const [band, setBand] = useState<any>(null);
  console.log("kakao in component :", kakao);

  useEffect(() => {
    (async () => {
      await loadScript();
      const zzz = window.Kakao.init("d4121d1d25060425010a7d8c13b2ac89");
      setKakao(window.Kakao);
    })();
  }, []);

  const [flag, setFlag] = useState(false);
  useEffect(() => {
    // first
    // return () => {
    //   second
    // }
    // setTimeout(() => {})
    // setInterval(() => {
    //   setFlag((b) => !b);
    // }, 1000);
  }, []);

  useEffect(() => {
    (async () => {
      const elem: any = document.querySelector(".span");
      elem.textContent = "z";
      console.log("elem :", elem);

      const span = document.createElement("span");
      elem.appendChild(span);

      const script1 = document.createElement("script");
      const script2: any = document.createElement("script");
      script1.src = "//developers.band.us/js/share/band-button.js?v=04122024";
      script1.type = "text/javascript";
      script1.onload = () => {
        console.log("222222222222222222222222 run");

        span.appendChild(script2);
        setFlag((b) => !b);
      };
      script2.type = "text/javascript";
      script2.textContent = `console.log(window.ShareBand.Button);console.log(window.ShareBand.makeButton);window.ShareBand.makeButton({ lang: "ko-KR", type: "a" }); console.log(123)`;

      console.log("11111111111111111111111 run");
      span.appendChild(script1);

      // elem.appendChild
      // script1
      // <script type="text/javascript">
      {
        /* console.log(12345) */
      }

      // </script>
      // nodeScriptReplace(document.querySelector(".tar1"))

      // const scString = `
      // <span>
      //   <script type="text/javascript" src="//developers.band.us/js/share/band-button.js?v=04122024"></script>
      //   <script type="text/javascript">
      //   console.log(12345)

      //   </script>
      // </span>
      // `;
      // const scriptEl = document.createRange().createContextualFragment(scString);
      // elem.append(scriptEl);
      // await loadBandScript();
      // const script = document.querySelector("#asdf")
      // document.head.appendChild(script as any);

      // console.log('window.ShareBand :', window.ShareBand);

      // setBand(window.ShareBand);
      // console.log("band :", band.makeButton({ lang: "ko-KR", type: "a" }));
      // const res = band.makeButton({ lang: "ko-KR", type: "a" });
      // console.log("res :", res);
      // const Bb:any = band
      // const BbIns = new Bb()
      // band.ma
      // console.log('BbIns :', BbIns);
      // console.log('bb :', bb);
      // const zzz = window.Kakao.init("d4121d1d25060425010a7d8c13b2ac89");
      // setKakao(window.Kakao);
    })();
  }, []);

  const handleClick = () => {
    kakao.Share.sendDefault({
      objectType: "feed",
      content: {
        title: "티샷 연단체 서비스 오픈",
        description: "티샷에서 연 단체 신청해드립니다.",
        imageUrl: "https://api.v2.teeshot.co.kr/event/resource/images/yb_popup.png",
        link: {
          // [내 애플리케이션] > [플랫폼] 에서 등록한 사이트 도메인과 일치해야 함
          mobileWebUrl: "https://stellar-engineers-949419.framer.app",
          webUrl: "https://stellar-engineers-949419.framer.app",
        },
      },
      buttons: [
        {
          title: "보러가기",
          link: {
            mobileWebUrl: "https://stellar-engineers-949419.framer.app",
            webUrl: "https://stellar-engineers-949419.framer.app",
          },
        },
        // {
        //   title: "앱으로 보기",
        //   link: {
        //     mobileWebUrl: "https://stellar-engineers-949419.framer.app/",
        //     webUrl: "https://stellar-engineers-949419.framer.app/",
        //   },
        // },
      ],
    });
  };

  const zzzz = useMemo(() => 1, []);

  const alert = useAlert();

  return (
    <>
      <a href="sms:+1234567890?body=Hello%20World">문자 보내기</a>
      <a href="sms:+01052316022?body=Hello%20World">문자 보내기</a>
      <a href="sms:+0?body=Hello%20World">문자 보내기</a>
      <div>
        111
        {String(flag)}
      </div>
      <div>
        <span className="span"></span>
      </div>
      <div className="md">
        <button
          onClick={() => {
            handleClick();
          }}
        >
          카카오공유버튼
        </button>
      </div>

      <Container className="ffff">
        <hr />
        accept="image/png, image/gif, image/jpeg{" "}
        <input type="file" accept="image/png, image/gif, image/jpeg" />
        <hr />
        image/png,image/jpeg
        <input type="file" accept="image/png,image/jpeg" />
        <hr />
        image/*
        <input type="file" accept="image/*" />
        <hr />
        capture u accept="image/png, image/gif, image/jpeg{" "}
        <input type="file" accept="image/png, image/gif, image/jpeg" capture="user" />
        <hr />
        capture u image/png,image/jpeg
        <input type="file" accept="image/png,image/jpeg" capture="user" />
        <hr />
        capture u image/*
        <input type="file" accept="image/*" capture="user" />
        <hr />
        capture e accept="image/png, image/gif, image/jpeg{" "}
        <input type="file" accept="image/png, image/gif, image/jpeg" capture={"environment"} />
        <hr />
        capture e image/png,image/jpeg
        <input type="file" accept="image/png,image/jpeg" capture={"environment" as any} />
        <hr />
        capture e image/*
        <input type="file" accept="image/*" capture={"environment" as any} />
        <hr />
        <div>
          <button
            onClick={() => {
              (async () => {
                alert({ content: "alert1", onClose: () => alert.resume() });
                await alert.pause();
              })();
            }}
          >
            alert 1
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              (async () => {
                alert({ content: "alert1", onClose: () => alert.resume() });
                await alert.pause();
              })();
            }}
          >
            alert 2
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              (async () => {
                alert({ content: "alert2", onClose: () => alert.resume() });
                await alert.pause();
              })();
            }}
          >
            alert 3
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              (async () => {
                alert({ content: "alert3", onClose: () => alert.resume() });
                await alert.pause();
              })();
            }}
          >
            alert 4
          </button>
        </div>
        <div>{count}</div>
        <button
          onClick={() => {
            setCount((c) => c + 1);
          }}
        >
          d
        </button>
        {/* <div className="inner">
        {arr.map((x) => {
          return <div className="cc">{x}</div>;
        })}
      </div> */}
        <Chil zz={fn1} />
      </Container>
    </>
  );
}

const Container = styled.div`
  /* flex: 1;
  min-height: 0;
  height: 100%;

  overflow: hidden;

  .inner {
    height: 100%;
    overflow: auto;
    background-color: coral;
    .cc {
      margin: 5px;
      background: #000;
    }
  } */
`;

const Chil = (props: { zz: any }) => {
  // console.log(props.zz);

  useEffect(() => {
    // console.log(" 나: load");
  }, []);

  return <div>나나</div>;
};

function nodeScriptReplace(node: any) {
  if (nodeScriptIs(node) === true) {
    node.parentNode.replaceChild(nodeScriptClone(node), node);
  } else {
    var i = -1,
      children = node.childNodes;
    while (++i < children.length) {
      nodeScriptReplace(children[i]);
    }
  }

  return node;
}
function nodeScriptClone(node: any) {
  var script = document.createElement("script");
  script.text = node.innerHTML;

  var i = -1,
    attrs = node.attributes,
    attr;
  while (++i < attrs.length) {
    script.setAttribute((attr = attrs[i]).name, attr.value);
  }
  return script;
}

function nodeScriptIs(node: any) {
  return node.tagName === "SCRIPT";
}
